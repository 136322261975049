import React, { useEffect, useState } from "react";
import { getServiceProject } from "../supa-base-client/services";
import Card from "components/cards/card";
import ShadowButton from "components/buttons/shadowButton";
import { Button, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { getServices } from "util/methods";

export default function Services() {
  const [project, setProject] = useState([]);
  useEffect(() => {
    async function getService() {
      try {
        const result = await getServiceProject();
        setProject(result);
      } catch (err) {
        setError({ error: true, message: err });
      }
    }
    getService();
  }, []);

  const navigate = useNavigate();
  const navigateToServiceInfo = (pro) => {
    navigate(`/Trainings/description?id=${pro.id}`, { state: { pro } });
  };
  return (
    <>
      <div>
        <span className="text-lato mt-5 mb-5 md:text-xl text-lg flex flex-row justify-center items-center">
          Trainings Offered
        </span>
        <div className="flex w-full justify-center">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            {project &&
              project.map((project) => (
                <Card
                  title={
                    <>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col gap-2">
                          <span className="text-lato text-title">
                            {project.service_name}
                          </span>
                          {/* <span className="text-lato text-sub-title">
                            {project.level}
                          </span> */}
                        </div>
                        {/* <Button
                          style={{
                            backgroundColor: "#7BF27C",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="text-lato btn-text">
                            {"6 months"}
                          </span>
                        </Button> */}
                      </div>
                    </>
                  }
                  body={
                    <>
                      <div className="flex flex-col gap-5">
                        <span className="text-poppins md:text-lg text-sm text-gray-500">
                          Duration: {project.duration}
                        </span>
                      </div>
                    </>
                  }
                  button={
                    <ShadowButton
                      onclick={() => navigateToServiceInfo(project)}
                      buttonName={"Explore"}
                      width={"50%"}
                    />
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
