import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import supabase from "pages/supa-base-client/supabase";
import { v4 as uuidv4 } from "uuid";
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { cashfree } from "util/cashfree";
import { CONFIG } from "lib/config";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Card,
  Flex,
  TimePicker,
  Upload,
  Result,
  Descriptions,
  notification,
  message,
  Divider,
} from "antd";
import {
  getCouponValidity,
  getCourseBatch,
  getNotification,
  getPaymentResponse,
} from "util/methods";
const Option = { Select };

const Forms = ({ id, title = "" }) => {
  if (!id || id === null || id == "") return <></>;

  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [type, setType] = useState("Submit");
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [requirePayment, setRequirePayment] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [discount, setDiscount] = useState({
    coupon: "",
    amount: 0,
    type: "percentage",
  });

  const applyCoupon = async () => {
    const values = form.getFieldsValue();
    const { coupon } = values;
    const res = await getCouponValidity(coupon);
    console.log(res);
    if (!res || !res?.valid) {
      getNotification(
        api,
        "error",
        "Coupon invalid",
        "please enter the valid coupoun code furthur queries please contact the coupon provider"
      );
      return;
    }
    setDiscount({ coupon: coupon, amount: res.amount, type: res.type });
    getNotification(
      api,
      "success",
      "coupon valid",
      "please proceed to checkout to activate the coupon discount"
    );
  };

  useEffect(() => {
    const formProm = supabase.from("forms").select().eq("id", id);
    formProm
      .then(({ data, error }) => {
        console.log({ data, error });
        if (error !== undefined && error !== null) {
          //window.location.href = "/";
        }
        const formRes = data[0];
        switch (formRes.related_to) {
          case "service":
          case "event":
            setType("Register");
            break;
          case "career":
            setType("Apply");
            break;
          default:
            setType("Submit");
            break;
        }
        if (formRes.type === "payment") {
          setRequirePayment(true);
          setAmount(formRes.amount)
          setTotal(formRes.amount)
        }
        const fieldsProm = supabase
          .from("form_fields")
          .select()
          .eq("form_id", formRes.id)
          .order("order", { ascending: true });
        fieldsProm.then((data) => {
          setFields(data.data);
        });
        setFormData(formRes);
      })
      .catch(() => {
        window.location.href = "/";
      });
  }, [id]);

  const onFinish = (values) => {
    Object.keys(values).forEach((x) => {
      if (x.includes("FileInput")) {
        let file = values[x].file;

        const file_id = uuidv4() + file.originFileObj.name;
        values[x] = file_id;
        supabase.storage
          .from("form-files")
          .upload(file_id, file.originFileObj, {
            cacheControl: "3600",
            upsert: false,
          });
      }
    });

    try{
      const registerData = {
        form_id: id,
        data: values,
        amount: getTotal(),
        coupon: discount.coupon,
        name: values["name"],
        email: values["email"],
        phone: values["phone"],
      };

      fetch(CONFIG.API_URL + "/api/v1/pay/payform", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registerData),
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((res) => {
              console.log({ res });
              let id = res.data;
              let checkoutOptions = {
                paymentSessionId: id,
                redirectTarget: "_blank",
              };
              if (id === null || id === undefined) {
                message.error(
                  "Error in initiating the payments. Please try again!"
                );
                return;
              }
              console.log(checkoutOptions, "sasdas");
              cashfree.checkout(checkoutOptions);
            });
          } else {
            message.error(
              "Error in initiating the payments. Please try again!"
            );
          }
        })
        .catch((error) => {
          console.error("Error initiating payment:", error);
          message.error("Encountered error! Please try again later");
        });
    }
    catch (error) {
      console.error("Error initiating payment:", error);
      message.error("Encountered error! Please try again later");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const getDiscount = (value) => {
    let amount1 = amount;
    if (value.amount === 0) return 0;
    let temp = 0;
    if (value.type === "percentage") {
      temp = (amount1 / 100) * value.amount;
    }
    else
      temp = value.amount;
    return Number(temp.toFixed(2));
  };

  const getTotal = () => {
    return amount - getDiscount(discount);
  }

  return (
    <>
      {contextHolder}
      {isError && <Result status="error" title="Submission Failed"></Result>}
      {isSuccess && (
        <Result status="success" title="Submission Success"></Result>
      )}
      {!isError && !isSuccess && (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          {fields?.map((x) => {
            let input = <></>;
            let name = x.name;
            switch (x.type) {
              case "text": {
                input = <Input size="large" placeholder={x.placeholder} />;
                break;
              }
              case "textarea": {
                input = <Input size="large" placeholder={x.placeholder} />;
                break;
              }
              case "number": {
                input = (
                  <InputNumber
                    size="large"
                    placeholder={x.placeholder}
                    style={{ width: "100%" }}
                  />
                );
                break;
              }
              case "switch": {
                input = (
                  <Switch
                    size="large"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                );
                break;
              }
              case "date": {
                input = <DatePicker size="large" style={{ width: "100%" }} />;
                break;
              }
              case "time": {
                input = <TimePicker size="large" style={{ width: "100%" }} />;
                break;
              }
              case "file": {
                name = name + "FileInput";
                input = (
                  <>
                    <Upload>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </>
                );
                break;
              }
              case "mcq": {
                input = (
                  <Select size="large" placeholder={x.placeholder}>
                    {x.data.map((y) => (
                      <Option key={y} value={y}>
                        {y}
                      </Option>
                    ))}
                  </Select>
                );
                break;
              }
              case "amount": {
                input = (
                  <Select onChange={(value => setAmount(value.split('|')[1]*1))} size="large" placeholder={x.placeholder}>
                    {x.data.map((y) => (
                      <Option key={y.name+"|"+y.value} value={y.name+"|"+y.value}>
                        {y.name}
                      </Option>
                    ))}
                  </Select>
                );
                break;
              }
              default: {
                input = <Input size="large" placeholder={x.placeholder} />;
                break;
              }
            }
            return (
              <Form.Item
                // layout={x.type === "textarea" ? "vertical" : "horizontal"}
                layout="horizontal"
                key={x.name}
                label={x.label}
                name={name}
                rules={[
                  {
                    required: x.is_required,
                    message: x.placeholder,
                  },
                ]}
              >
                {input}
              </Form.Item>
            );
          })}
          {requirePayment && (
            <>
              <Form.Item className="text-lato" name="coupon">
                <div className="flex flex-row gap-4">
                  <Input
                    style={{ height: "50px" }}
                    placeholder="Enter Coupon Code"
                  />
                  <Button
                    onClick={() => applyCoupon()}
                    style={{
                      boxShadow: "6px 6px 0px 3px #00B902",
                    }}
                    className="text-lato"
                  >
                    Apply
                  </Button>
                </div>
              </Form.Item>
              <Card title="Summary">
                <Descriptions title={title} layout="horizontal">
                  {/* <Descriptions.Item label="Selected" span={2}>
                    {title}
                  </Descriptions.Item> */}
                </Descriptions>

                <Divider orientation="right" dashed>
                  Sub Total : {"₹ " + amount}
                </Divider>
                <Divider orientation="right" dashed>
                  Applied Discount : {"₹ " + getDiscount(discount)}
                </Divider>
                <Divider orientation="right">
                  Grand Total : {"₹ " + getTotal()}
                </Divider>
              </Card>
            </>
          )}
          <Form.Item
            className="mt-4"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              {type}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
export default Forms;
