import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationPin,
  faUserPlus,
  faEye,
  faUsers,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./event.css";
import car6 from "images/car6.jpg";
import { faBuilding, faClock } from "@fortawesome/free-regular-svg-icons";
import AvatarCard from "./avatarCard";
import { Button, Avatar } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getSelectedEvent } from "../supa-base-client/events";
import HomeHeader from "../../components/header/homeHeader";
import Forms from "components/forms/form";
import Profile from "../../images/profile.png";
import Techknow from "components/forms/techknow";

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] ??= []).push(x);
    return rv;
  }, {});
}

export default function EventDescription() {
  const navigate = useNavigate();
  const location = useLocation();
  let { eventinfo: event } = location.state || {};
  const [eventinfo, setEventInfo] = React.useState(event || {});
  const [error, setError] = useState({ error: false, message: "no error" });
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [showForm, setShowForm] = useState(false);
  const [searchParams] = useSearchParams();
  const [panel, setPanel] = useState("");
  const targetRef = useRef(null);
  const id = searchParams.get("slug");
  React.useEffect(() => {
    const fetchCourse = async () => {
      try {
        if (!eventinfo || Object.keys(eventinfo).length === 0) {
          const result = await getSelectedEvent(id);
          setEventInfo(result[0]);
        }
      } catch (err) {
        setError({ error: true, message: err });
      }
    };

    fetchCourse();
  }, [id]);

  const navigateToCareerApply = () => {
    setShowForm(true);
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth", // Smooth scrolling animation
        block: "start", // Scroll to the top of the component
      });
    }
  };
  console.log(eventinfo);
  // const creators = eventinfo !== undefined && eventinfo !== null ? eventinfo.panalist_name.filter(x => x.panel === "Creators") : [];
  // const founders = eventinfo !== undefined && eventinfo !== null ? eventinfo.panalist_name.filter(x => x.panel === "Founders") : [];
  // const academic = eventinfo !== undefined && eventinfo !== null ? eventinfo.panalist_name.filter(x => x.panel === "Academic") : [];
  // const keySpeaker = eventinfo !== undefined && eventinfo !== null ? eventinfo.panalist_name.filter(x => x.panel === "Key Note Speaker")[0] : undefined;

  const date2 = new Date(eventinfo.deadline);
  const date1 = new Date();

  const timeDiff = date2 - date1; // Difference in milliseconds
  const daysDiff = timeDiff / (1000 * 60 * 60 * 24); // Convert milliseconds to days

  const deadline =
    daysDiff <= 0
      ? `${Math.floor(daysDiff)} days left`
      : `${date2.getDate()}th ${date2.toLocaleString("default", {
          month: "long",
        })} ${date2.getFullYear()}`;
  console.log(eventinfo.panelist);

  return (
    <div className="">
      <div className="flex flex-col md:flex-row w-full gap-10 mt-5">
        {/* Left side - 60% width */}
        <div className="flex flex-col gap-3 w-full md:w-[60%]">
          <div className="course-box background-img flex gap-4 flex-col">
            <span
              className="md:text-xl text-sm text-poppins text-nowrap"
              style={{ color: "#00B902" }}
            >
              Knowvation Learnings Pvt Ltd
            </span>
            <div className="flex flex-col gap-2">
              <span className="text-lato text-title text-nowrap">
                {eventinfo.title}
              </span>
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faBuilding} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  {eventinfo.venue}
                </span>
              </div>
              <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faClock} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  {eventinfo.dates}
                </span>
              </div>
              <div className="flex flex-row items-center justify-between m-3 gap-2 text-nowrap">
                <div className="flex flex-row items-center gap-3">
                  <FontAwesomeIcon icon={faLocationPin} />
                  <span className="text-poppins md:text-lg text-sm text-gray-500">
                    {eventinfo.location}
                  </span>
                </div>
                <Button
                  onClick={navigateToCareerApply}
                  className="text-lato"
                  style={{
                    padding: 10,
                    borderColor: "black",
                    boxShadow: "6px 8px 0px 4px #00B902",
                    width: "30%",
                    height: "100%",
                  }}
                  disabled={eventinfo.is_done}
                >
                  {eventinfo.is_done ? " Registrations Closed" : "Register Now"}
                </Button>
              </div>
            </div>
          </div>
          {!eventinfo.is_done && (
            <>
              <div className="md:flex md:flex-row grid grid-cols-2 course-box justify-around">
                {/* <div className="flex flex-row items-center gap-4">
                <FontAwesomeIcon icon={faUserPlus} color="#7BF27C" />
                <span className="flex flex-col text-poppins md:text-lg text-xs">
                  Registered Users<span>289</span>
                </span>
              </div> */}

                <div className="flex flex-row items-center gap-4">
                  <FontAwesomeIcon icon={faClock} color="#7BF27C" />
                  <span className="flex flex-col justify-center text-poppins md:text-lg text-xs">
                    Registration Deadline<span>{deadline}</span>
                  </span>
                </div>

                {/* <div className="flex flex-row items-center gap-4">
                <FontAwesomeIcon icon={faEye} color="#7BF27C" />
                <span className="flex flex-col text-poppins md:text-lg text-xs">
                  Impressions<span>289</span>
                </span>
              </div> */}

                <div className="flex flex-row items-center gap-4">
                  <FontAwesomeIcon icon={faUsers} color="#7BF27C" />
                  <span className="flex flex-col justify-center text-poppins md:text-lg text-xs">
                    Team Size for Hackathon<span>2-4</span>
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col course-box">
            <span className="text-poppins md:text-xl text-lg">Panelists</span>
            <div className="grid grid-cols-2 md:grid-cols-5 justify-around items-center">
              <div onClick={() => setPanel("Founders")}>
                <AvatarCard
                  panel="Founders"
                  image={"https://upcdn.io/FW25c5x/raw/landing/th%20(1).jpg"}
                />
              </div>
              <div onClick={() => setPanel("Corporates")}>
                <AvatarCard
                  panel="Corporates"
                  image={"https://upcdn.io/FW25c5x/raw/landing/corp.jpg"}
                />
              </div>
              <div onClick={() => setPanel("Creators")}>
                <AvatarCard
                  panel="Influencers"
                  image={"https://upcdn.io/FW25c5x/raw/landing/inf.jpg"}
                />
              </div>
              <div onClick={() => setPanel("Academic")}>
                <AvatarCard
                  panel="Academic"
                  image={"https://upcdn.io/FW25c5x/raw/landing/ac.webp"}
                />
              </div>
              <div onClick={() => setPanel("Key Note Speaker")}>
                <AvatarCard
                  panel="Guest Speaker"
                  image={
                    "https://upcdn.io/FW25c5x/raw/landing/PM-please_welcome_our_guest_speaker-title-2-Wide-16x9-1.jpg"
                  }
                />
              </div>
              {eventinfo.panalist_name &&
                Object.keys(groupBy(eventinfo.panalist_name, "panel")).map(
                  (x) => {}
                )}

              {eventinfo.panalist_name &&
                panel &&
                eventinfo.panalist_name
                  .filter((panelist) => panelist.panel === panel)
                  .map((panelist) => (
                    <>
                      {/* <div className="flex flex-col items-center text-center">
                        <Avatar
                          size={80}
                          src={
                            <img src={panelist.image} alt="panelist image" />
                          }
                        />
                        <span className="text-poppins">{panelist.name}</span>
                      </div> */}
                      {panelist.confirm && <AvatarCard {...panelist} />}
                    </>
                  ))}
            </div>
          </div>
          <div className="course-box flex flex-col">
            {/* <span className="text-poppins md:text-xl text-lg">
              About {eventinfo.title}
            </span> */}
            <span className="text-poppins md:text-sm text-xs">
              <div
                dangerouslySetInnerHTML={{ __html: eventinfo.content }}
              ></div>
            </span>
          </div>

          {/* <div className="course-box flex flex-col">
            <span className="text-poppins md:text-xl text-lg">
              Requirements
            </span>
            <span className="text-poppins md:text-sm text-xs">
              <FontAwesomeIcon icon={faDotCircle} color="green" /> Assist in the
              development and execution of digital marketing campaigns across
              various platforms, including social media, email marketing, and
              search engine marketing.
            </span>
          </div> */}
        </div>
        {eventinfo.is_done ? (
          <div className="w-full md:w-[40%]">
            <EventGallery src={eventinfo.img} />
          </div>
        ) : (
          <>
            <div className="flex flex-col-reverse md:flex-col gap-3 w-full md:w-[40%]">
              {/* <div className="course-box flex flex-col gap-3">
              <span className="text-poppins md:text-lg text-sm text-nowrap">
                More Events
              </span>
              <div className="flex flex-row items-center gap-5">
                <div className="flex flex-row items-center gap-2 text-nowrap">
                  <FontAwesomeIcon icon={faClock} />
                  <span className="text-poppins md:text-lg text-xs text-gray-500">
                    {"24 May 2024 | 09AM - 12PM"}
                  </span>
                </div>
                <div className="flex flex-row items-center gap-2 text-nowrap">
                  <FontAwesomeIcon icon={faLocationPin} />
                  <span className="text-poppins md:text-lg text-xs text-gray-500">
                    {"Hyderabad"}
                  </span>
                </div>
              </div>
            </div> */}
              {(showForm || isMobile) && (
                <>
                  <div
                    ref={targetRef}
                    className="course-box flex flex-col gap-3"
                  >
                    <Techknow />
                  </div>
                </>
              )}
              <div className="course-box flex flex-col gap-3">
                <img
                  src={eventinfo.img}
                  alt="Techknow 1.0"
                  className="w-full"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function EventGallery({ src }) {
  const imageurls = [
    "https://upcdn.io/FW25c5x/raw/landing/DSC06743.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/DSC06868.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/DSC07588.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/DSC07942.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/DSC08981%20-%20Copy.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/SBC06821.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/SBC06895.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/SBC07316.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/SBC07563.JPG",
    "https://upcdn.io/FW25c5x/raw/landing/SBC07869.JPG",
    //"https://upcdn.io/FW25c5x/raw/landing/SBH00118.JPG"
  ];

  const [showAll, setShowAll] = useState(false);
  const [showCompleteGallery, setShowCompleteGallery] = useState(false);
  const initialBatch = 4;
  const secondBatch = 6;

  const visibleImages = showAll ? imageurls : imageurls.slice(0, initialBatch);

  const handleViewMore = () => {
    setShowAll(true);
    setShowCompleteGallery(true);
  };

  const navigateToCompleteGallery = () => {
    console.log("Navigating to complete gallery");
  };

  return (
    <div className="course-box flex flex-col w-full">
      <span className="text-poppins text-xl text-lg mb-4">Event Gallery</span>
      <div className="grid grid-cols-2 gap-4">
        {visibleImages.map((url, index) => (
          <div
            key={index}
            className="w-full aspect-square overflow-hidden rounded-lg"
          >
            <img
              src={url}
              alt={`Event Image ${index + 1}`}
              className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
            />
          </div>
        ))}
      </div>

      {!showAll && imageurls.length > initialBatch && (
        <button
          onClick={handleViewMore}
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:green-600 self-center"
        >
          View More
        </button>
      )}

      {showCompleteGallery && (
        <a
          href="https://www.playbook.com/s/goldmenmedia/1AfcgDCJjcVG6rZcZj1ocnGJ"
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 self-center"
        >
          Click here to View complete gallery
        </a>
      )}
    </div>
  );
}
