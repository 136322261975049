import React from "react";
import { Button, Avatar } from "antd";

export default function avatarCard({ name, image, profile, panel }) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <div className="card-cst w-[20%]">
      <Avatar
        className="border-r-0 rounded-none"
        size={isMobile ? 100 : 150}
        src={<img src={image} alt="panelist image" />}
      />
      <div className="card-cst-title">
        {name}
        <br />
        <span>
          {panel} {panel.startsWith("Key") ? "" : "Panel"}
        </span>
      </div>
      <div className="card-cst-socials">
        <button className="card-socials-btn facebook"></button>
        <button className="card-socials-btn github"></button>
        <button className="card-socials-btn linkedin"></button>
      </div>
    </div>
  );
}
