import React from "react";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../util/methods";
import PinkShadowButton from "components/buttons/pinkButton";

const { Meta } = Card;
const date = "09 May 2024";
const title = "Marketing";

export default function BlogsCard({ blog }) {
  const navigate = useNavigate();
  const navigateToBlogDescription = (blog) => {
    navigate(`/Blogs/description?id=${blog.id}`, { state: { blog } });
  };
  return (
    <>
      {blog && (
        <Card
          style={{ width: "100%" }}
          cover={<img alt="example" src={blog.image} />}
        >
          <div className="flex flex-col gap-2">
            <Meta title={`${blog.title}`} />
            <Meta
              title={
                <span className="text-poppins md:text-sm text-xs inline-flex gap-2">
                  <span>{formatDate(blog.created_at)}</span>
                  <span>|</span>
                  <span style={{ color: "black" }}>{blog.domain}</span>
                </span>
              }
              description={`${blog.description}`}
            />
            <PinkShadowButton onClick={() => navigateToBlogDescription(blog)}>
              Read More
            </PinkShadowButton>
          </div>
        </Card>
      )}
    </>
  );
}
