import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDotCircle,
  faIndianRupeeSign,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { formatDate, getEmploymentType, getRoleType } from "util/methods";
import { Button, Form, Input } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getSelectedCareer } from "../supa-base-client/careers";
import Markdown from "react-markdown";
import Forms from "components/forms/form";

export default function CareerDescription() {
  const location = useLocation();
  let { career: careerProp } = location.state || {};
  const [career, setCareer] = React.useState(careerProp || {});
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [formtype, setFormtype] = useState("");
  const [error, setError] = useState({ error: false, message: "no error" });
  React.useEffect(() => {
    const fetchCourse = async () => {
      if (!career || Object.keys(career).length === 0) {
        try {
          const result = await getSelectedCareer(id);

          setCareer(result[0]);
        } catch (err) {
          setError({ error: true, message: err });
        }
      }
    };

    fetchCourse();
  }, [id]);
  const navigate = useNavigate();
  const navigateToCareerApply = () => {
    navigate(`/Careers/description/apply?id=${id}&form=career`);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row w-full gap-10 mt-5">
        <div className="flex flex-col gap-3 md:w-3/5 w-full">
          <div className="course-box background-img flex gap-4 flex-col">
            <span
              className="md:text-xl text-sm text-poppins text-nowrap"
              style={{ color: "#00B902" }}
            >
              Knowvation Learnings Pvt Ltd
            </span>
            <div className="flex flex-col gap-2">
              <span className="text-lato text-title text-nowrap">
                {career.career_name}
              </span>
              <span className="text-lato text-sub-title">
                {getEmploymentType(career.emplyment_type)}
              </span>
            </div>
            <div className="flex flex-row items-center gap-5">
              <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faLocationPin} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  {getRoleType(career.role_type)}
                </span>
              </div>
              <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faCalendar} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  {career.duration}
                </span>
              </div>
              {/* <div className="flex flex-row items-center gap-2 text-nowrap">
                <FontAwesomeIcon icon={faIndianRupeeSign} />
                <span className="text-poppins md:text-lg text-sm text-gray-500">
                  {career.salary}
                </span>
              </div> */}
            </div>
            <div className="flex flex-row gap-10">
              <span className="text-poppins text-gray-500 md:text-lg text-sm">
                No of Openings : {career.openings}
              </span>
              <span className="text-poppins text-gray-500 md:text-lg text-sm">
                Salary :{" "}
                {career.salary === "0"
                  ? "Incentives based on performance"
                  : career.salary}
              </span>
            </div>
            <div className="flex flex-row gap-4 justify-between pr-4 pb-3">
              <span className="text-gray-500 text-poppins md:text-xl text-sm">
                Posted :
                <span className="text-poppins text-black">
                  {formatDate(new Date(career.created_at))}
                </span>
              </span>
              {/* <Button
                onClick={navigateToCareerApply}
                className="text-lato"
                style={{
                  padding: 10,
                  borderColor: "black",
                  boxShadow: "6px 8px 0px 4px #00B902",
                }}
              >
                Apply Now
              </Button> */}
            </div>
          </div>
          <div className="course-box flex flex-col">
            <span className="text-poppins md:text-xl text-lg">
              Job Description
            </span>
            <span className="text-poppins md:text-sm text-xs">
              <Markdown>{career.job_description}</Markdown>
            </span>
          </div>
          <div className="course-box flex flex-col">
            <span className="text-poppins md:text-xl text-lg">
              Responsbilities
            </span>
            <span className="text-poppins md:text-sm text-xs">
              {/* <FontAwesomeIcon icon={faDotCircle} color="green" />{" "} */}
              <Markdown>{career.responsibilites}</Markdown>
            </span>
          </div>

          <div className="course-box flex flex-col">
            <span className="text-poppins md:text-xl text-lg">Why Apply?</span>
            <span className="text-poppins md:text-sm text-xs">
              {/* <FontAwesomeIcon icon={faDotCircle} color="green" />{" "} */}
              <Markdown>{career.experience}</Markdown>
            </span>
          </div>
          <div className="course-box flex flex-col">
            <span className="text-poppins md:text-xl text-lg">
              Key Skills Required
            </span>
            <div className="flex flex-row gap-3">
              {career.key_skills &&
                career.key_skills.split(",").map((x) => (
                  <span
                    style={{
                      borderWidth: "2px",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    {x}
                  </span>
                ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 w-full md:w-2/5">
          <div className="course-box flex flex-col">
            <span className="text-lato text-black md:text-lg md:mb-4 mb-2 text-sm">
              Apply Now
            </span>
            {/* <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
            >
              <Form.Item name="name" label="Full Name" required={true}>
                <Input placeholder="Enter Your Name" />
              </Form.Item>
              <Form.Item name="email" label="Email" required={true}>
                <Input type="email" placeholder="Enter your email" />
              </Form.Item>
              <Form.Item name="phone" label="Phone Number" required={true}>
                <Input placeholder="Enter your Phone Number" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8 }}>
                <Button
                  className="text-lato"
                  style={{
                    borderColor: "black",
                    boxShadow: "6px 8px 0px 4px #00B902",
                  }}
                >
                  Apply Now
                </Button>
              </Form.Item>
            </Form> */}
            <Forms id={career.form_id} />
          </div>
        </div>
      </div>
    </>
  );
}
