import React, { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import PaymentFail from "./paymentFail";
import { useSearchParams } from "react-router-dom";
import { counter } from "@fortawesome/fontawesome-svg-core";
import { useCounter } from "pages/context/counterContext";
import { CONFIG } from "lib/config";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const { removeItem, isExists } = useCounter();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("paymentid") || null);
  const [orderId, setOrderId] = useState(searchParams.get("order_id") || null);
  const [status, setStatus] = useState(searchParams.get("status") || null);

  const goToCourses = () => {
    navigate("/courses");
  };
  const goToDashBoard = () => {
    navigate("/dashboard");
  };

  let message = "";

  return (
    <>
      {status === "SUCCESS" ? (
        <Result
          status="success"
          title="Payment Successful!"
          subTitle="Your Educational Transformation starts now!"
          extra={[
            <p>Your Transaction ID : {id}</p>,
            <p>Your Order ID : {orderId}</p>,
            // <Button onClick={goToDashBoard} type="primary" key="console">
            //   Go To Dashboard
            // </Button>,
            <Button onClick={goToCourses} key="buy">
              Browse Courses
            </Button>,
          ]}
        />
      ) : (
        <Result
          status="error"
          title="Payment is not succesfull!"
          subTitle="If money has been deducted from your account. Please contact our support team."
          extra={[
            <p>Your Transaction ID : {id}</p>,
            // <Button onClick={goToDashBoard} type="primary" key="console">
            //   Go To Dashboard
            // </Button>,
            <Button onClick={goToCourses} key="buy">
              Browse Courses
            </Button>,
          ]}
        />
      )}
    </>
  );
};
export default PaymentStatus;
