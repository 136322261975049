import React from "react";
import "./dashboardHeader.css";
import logo from "images/KnowvationIcon.jpeg";
import mobileLogo from "images/mobileIcon.jpeg";
import { Select, Space, Badge, Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faComment,
  faBell,
  faHeart,
} from "@fortawesome/free-regular-svg-icons";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../dropdown/dropDown";
import DrawerFun from "../drawer/Drawer";
import { useCounter } from "../../pages/context/counterContext";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";

import GreenShadowButton from "../buttons/greenButton";
import PinkShadowButton from "../buttons/pinkButton";

import { cn } from "../../lib/utils";

const components = [
  // {
  //   title: 'Courses',
  //   href: '/Courses?category=combats',
  //   description:
  //     'Learn the latest technologies from the best industry experts and gain hands-on experience.',
  // },
  {
    title: "Trainings",
    href: "/trainings",
    description:
      "Providing an exposure to the real-time corporate world with tailor-made opportunities.",
  },
  {
    title: "Blogs",
    href: "/blogs",
    description:
      "Displays an indicator showing the completion progress of a task, typically related to learning goals or achievements.",
  },
  {
    title: "Events",
    href: "/events",
    description:
      "A place for students to build a network of tech enthusiasts and transform their careers.",
  },
  {
    title: "Careers",
    href: "/careers",
    description:
      "Team of techies working towards a mission to revolutionize education in India. Can you be one of us?",
  },
  // {
  //   title: "Gallery",
  //   href: "/gallery",
  //   description:
  //     "A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it.",
  // },
];

export function NavigationMenuDemo() {
  return (
    <NavigationMenu className="z-[5] m750:max-w-[300px]">
      <NavigationMenuList className="m750:max-w-[300px]">
        <NavigationMenuItem>
          <Link to="/aboutus" className={navigationMenuTriggerStyle()}>
            <span className="m750:max-w-[80px] m750:text-xs">About Us</span>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="m750:max-w-[80px] m750:text-xs">
            Explore
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {components.map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link to="/contact" className={navigationMenuTriggerStyle()}>
            <span className="m750:max-w-[80px] m750:text-xs">Contact Us</span>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef(
  ({ className, title, children, href, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            ref={ref}
            to={href}
            className={cn(
              "hover:bg-accent block text-mtext select-none space-y-1 rounded-base border-2 border-transparent p-3 leading-none no-underline outline-none transition-colors hover:border-border dark:hover:border-darkBorder",
              className
            )}
            {...props}
          >
            <div className="text-base font-heading leading-none">{title}</div>
            <p className="text-muted-foreground font-base line-clamp-2 text-sm leading-snug">
              {children}
            </p>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";

export default function HomeHeader() {
  const { cartCount, wishCount } = useCounter();
  const currentPage = "dashboard";
  const navigate = useNavigate();

  const setDropDownNavigation = (value) => {
    navigate(`/${value}`);
  };
  const navigateToLogin = () => {
    navigate("/login");
  };
  const [open, setOpen] = React.useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <header>
        <div className="flex flex-row justify-between items-center">
          <div className="basis-1/4 flex flex-row gap-10 items-center">
            <Link to="/">
              <img
                className="web-logo"
                src={"/logonew.png"}
                alt="logo knowvation"
              />
              <img
                className="mobile-logo"
                src={"/logolight.png"}
                alt="logo knowvation"
              />
            </Link>

            {currentPage !== "dashboard" && (
              <Button className="dashboard-header-btn">Dashboard</Button>
            )}
          </div>
          <div className="flex basis-1/2 flex-row justify-end gap-10 items-center">
            <div className="hidden md:block">
              <NavigationMenuDemo />
            </div>
            {/* <div className="flex flex-row items-center gap-3">
              <Link to="/aboutus">
                <span className="text-poppins text-nowrap">About Us</span>
              </Link>
              <DropDown />
              <Link to="/contact">
                <span className="text-poppins text-nowrap">Contact Us</span>
              </Link>
                <a
                className="hidden md:block"
                href="https://icube.knowvationlearnings.in/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-poppins">iCube</span>
              </a>
            </div> */}

            <div className="flex flex-row gap-6 items-center mr-1">
              <Link to="/login">
                <PinkShadowButton
                  variant={"neutralPink"}
                  href="/login"
                  className="text-lato hidden md:flex"
                >
                  Login
                </PinkShadowButton>
              </Link>
              <Link to="/signup">
                <GreenShadowButton
                  href="/singup"
                  variant={"neutralGreen"}
                  className="text-lato hidden md:flex"
                >
                  Apply as Tutor
                </GreenShadowButton>
              </Link>
              <FontAwesomeIcon
                onClick={showDrawer}
                className="icons menu"
                icon={faBars}
              />
            </div>
            <Badge count={`${cartCount}`} color="#7BF27C">
              <Link to="/cart">
                <ShoppingCartOutlined className="icons" />
              </Link>
            </Badge>
          </div>
          <DrawerFun setOpen={setOpen} open={open} />
        </div>
      </header>
    </>
  );
}
