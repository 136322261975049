import supabase from "pages/supa-base-client/supabase";
import { getUserRegisteredCourses } from "pages/supa-base-client/user";
import { CaliculateDiscount } from "./caliculatePrice";
import { Descriptions } from "antd";
import { CONFIG } from "../lib/config";
const apiURL = CONFIG.API_URL;
const adminURL = CONFIG.ADMIN_URL;

export const formatDate = (d) => {
  d = new Date(d);
  return (
    ("0" + d.getDate()).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear()
  );
};

export const getEmploymentType = (value) => {
  switch (value) {
    case "fulltime":
      return "Full Time";
    case "parttime":
      return "Part Time";
    case "freelance":
      return "Freelancer";
    case "contract":
      return "Contract";
    case "intern":
      return "Internship";
  }
  return value;
};

export const getRoleType = (value) => {
  switch (value) {
    case "wfh":
      return "Work from Home";
    case "wfo":
      return "Work from Office (Hyderabad)";
    case "oc":
      return "OnCampus";
    case "hb":
      return "Hybrid (Hyderabad)";
  }
  return value;
};

export async function getUserCourses() {
  try {
    const courses = await getUserRegisteredCourses(user.id);
  } catch (err) {
    console.error(err);
  }
}

export async function getCourseBatch() {
  const { data, error } = await supabase.from("course_batch").select("*");
  if (error === null) return data;
  console.log(error);
}

export async function getPaymentResponse(formData) {
  try {
    const response = await fetch(apiURL + "/api/v1/pay/payme", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      return null;
    }

    const data = await response.json();

    return data.data.payment_session_id;
  } catch (err) {
    console.error(err);
  }
}

export async function getCouponValidity(coupon) {
  try {
    const response = await fetch(
      apiURL + "/api/payment/coupon?code=" + coupon,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    );
    if (!response.ok) {
      return null;
    }
    const body = await response.json();
    const data = body.data;
    if (body.code < 0) {
      return { valid: false, percentage: 0 };
    }
    if (data.uses < data.max_uses) {
      return { valid: true, amount: data.amount, type: data.type };
    }
    return { valid: false, percentage: 0 };
  } catch (err) {
    console.error(err);
  }
}

export async function getServices() {
  try {
    const response = await fetch(adminURL + "/api/landing/services", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    if (!response.ok) {
      return [];
    }
    const body = await response.json();
    return body;
  } catch (err) {
    console.error(err);
  }
  return [];
}

export function getNotification(api, type, message, description) {
  api[type]({
    message: `${message || "something went wrong"}`,
    description: `${description || "please try again after some time"}`,
  });
}
